export default {
    name: "UtiliseWarrantTools",
    created() {
        this.$nextTick(function () {
            this.$nextTick(function () {
                // this.$$.navbarAnimation()
            })
        });
    },
    mounted() {

    },
    data() {
        return {
            TradingC: [
                {
                    name: 'On the Maybank Trade Website, Click on Technical Analysis on the menu bar',
                    img: require('../../../../../../../public/image/trading_img_1.png')
                },
                {
                    name: 'Make informed decision by analysing the historical price and volume at various time',
                    img: require('../../../../../../../public/image/trading_img_2.png')
                },
                {
                    name: 'Use the Research & News function to search for various news on the',
                    img: require('../../../../../../../public/image/trading_img_3.png')
                },
                {
                    name: 'Our Maybank Research Team published research reports on the underlying shares, highlighting opinions and forecasting trends',
                    img: require('../../../../../../../public/image/trading_img_4.png')
                }
            ]
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {

    }
};